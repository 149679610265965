<template>
  <div class="row loading-skeleton    " style="width: 10rem;height: 10rem">
    <div class="col-6 bg-gray-300 rounded " style="height: 2.5rem;background-color: #d1d5db">
    </div>
    <div class="col-6 d-flex   flex-column">
      <small>lroe</small>
      <small>lro</small>
    </div>
    <div class="col-6 d-flex  flex-column">
      <small>llll</small>
      <small>las</small>
    </div>
    <div class="col-6 bg-gray-300 rounded " style="height: 2.5rem;background-color: #d1d5db">
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstPattern"
}
</script>

<style scoped>

.loading-skeleton h1, .loading-skeleton h2, .loading-skeleton h3, .loading-skeleton h4, .loading-skeleton h5, .loading-skeleton h6, .loading-skeleton p, .loading-skeleton li, .loading-skeleton .btn, .loading-skeleton span, .loading-skeleton small, .loading-skeleton i, .loading-skeleton a, .loading-skeleton strong, .loading-skeleton label, .loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}

.loading-skeleton h1::placeholder, .loading-skeleton h2::placeholder, .loading-skeleton h3::placeholder, .loading-skeleton h4::placeholder, .loading-skeleton h5::placeholder, .loading-skeleton h6::placeholder, .loading-skeleton p::placeholder, .loading-skeleton li::placeholder, .loading-skeleton .btn::placeholder, .loading-skeleton span::placeholder, .loading-skeleton small::placeholder, .loading-skeleton i::placeholder, .loading-skeleton a::placeholder, .loading-skeleton strong::placeholder, .loading-skeleton label::placeholder, .loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
  border-radius: 10px;
}

.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}

.loading-skeleton h1, .loading-skeleton h2, .loading-skeleton h3, .loading-skeleton h4, .loading-skeleton h5, .loading-skeleton h6, .loading-skeleton p, .loading-skeleton li, .loading-skeleton .btn, .loading-skeleton span, .loading-skeleton small, .loading-skeleton i, .loading-skeleton a, .loading-skeleton strong, .loading-skeleton label, .loading-skeleton .form-control {
  color: transparent !important;
  border-radius: 10px !important;
}

.loading-skeleton span {
  background-color: transparent !important;
  border-radius: 10px !important;
}

.loading-skeleton div {
  background-image: none !important;
  color: transparent !important;
  border-radius: 10px !important;
}

</style>
