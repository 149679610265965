<template>
  <div>
    <h1 class="mb-2">ساخت دسته بندی</h1>
    <b-card>

      <b-form>
        <b-row>
          <h2 class="mb-2">جزئیات</h2>
          <b-col
              cols="12"
              class="mb-1"
          >
          </b-col>

          <!-- Field: name -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="نام"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="defaultCreateData.name"
              />
            </b-form-group>
          </b-col>

          <!-- Field: type -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="نوع"
                label-for="type"
            >
              <v-select
                  v-model="defaultCreateData.type"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
              />
            </b-form-group>
          </b-col>

          <!-- Field: schema -->
          <b-col
              v-if="defaultCreateData.type === 2"
              cols="12"
              md="4"
          >
<!--            <FirstPattern></FirstPattern>-->
            <b-form-group
                label="الگو"
                label-for="schema"
            >
              <v-select
                  v-model="defaultCreateData.schema"
                  :options="schemaOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="type"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
      <b-card class="my-3">
        <b-form >
          <h2 class="mb-2">سئو</h2>
          <b-row>
            <b-col md="6" class="">
              <b-form-group
                  label="عنوان"
                  label-for="blog-edit-title"
                  class=""
              >
                <b-form-input
                    id="blog-edit-seoTitle"
                    v-model="defaultCreateData.seoTitle"
                />
              </b-form-group>
              <span class="" :class="{'text-danger':defaultCreateData.seoTitle.length>60}">تعداد کاراکترهای مجاز <small>{{ defaultCreateData.seoTitle.length }}</small><small
                  class="text-secondary">/</small><small class="text-secondary">60</small></span>
            </b-col>
            <b-col md="6" class="d-flex flex-row justify-content-between align-items-start">
              <b-form-group
                  label="آدرس"
                  label-for="blog-edit-title"
                  class="w-100"
              >
                <b-form-input
                    id="blog-edit-seourl"
                    v-model="allCharactersToDash"
                    v-on:focus="$event.target.select()"
                    ref="myInput"
                />
              </b-form-group>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="h-50 mt-1 ml-2"
                  @click="copyText"
              >
                کپی
              </b-button>
            </b-col>
            <!-- Field:  description -->
            <b-col cols="12" class="mt-2">
              <b-form-group
                  label="توضیحات"
                  label-for="blog-content"
                  class="mb-2"
              >
                <b-form-textarea
                    v-model="defaultCreateData.seoDescription"
                ></b-form-textarea>
              </b-form-group>
              <span class="" :class="{'text-danger':defaultCreateData.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{ defaultCreateData.seoDescription.length }}</small><small
                  class="text-secondary">/</small><small class="text-secondary">160</small></span>
            </b-col>
          </b-row>
        </b-form>

        <h2>نحوه نمایش در گوگل</h2>

        <div class="col-md-12 my-3">
          <!--- serp Desktop --->
          <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
            <div class="serp-title" >
              <h4 style="color: rgb(26, 13, 171)!important">
                {{defaultCreateData.seoTitle}}
              </h4>
            </div>
            <div class="serp-url">
              <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                <span style="overflow-wrap: anywhere">{{defaultCreateData.seourl.replaceAll(' ','-')}}/</span>
                <span>
                             {{ 'https://enzamode.ir/magazine' }}
                        <span class="serp-arrow"></span>
                      </span>

              </h5>

            </div>
            <div class="serp-description">
              <h6 style="overflow-wrap: anywhere">
                {{ defaultCreateData.seoDescription }}
              </h6>
            </div>
          </div>
          <!--- serp Mobile --->
          <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
            <div class="serp-title">
              <h6>
                {{defaultCreateData.seoTitle}}
              </h6>
            </div>
            <div class="serp-url d-flex align-items-center flex-wrap" >

              <small >{{defaultCreateData.seourl.replaceAll(' ','-')}}/</small>
              <small>
                {{ 'https://enzamode.ir/magazine' }}
                <small class="serp-arrow"></small>
              </small>
            </div>
            <div class="serp-description">
              <small>
                {{ defaultCreateData.seoDescription }}
              </small>
            </div>
          </div>
        </div>

        <!-- Action Buttons -->
        <b-button
            variant="primary"
            class="mt-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="createBlogCategory"
        >
          ثبت
        </b-button>
      </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm, BModal, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCategoryCreateRequest, BlogCategoryUpdateRequest} from "@/libs/Api/Blog";
import {BlogCategoryGetRequest} from "@/libs/Api/Blog";
import FirstPattern from "@/views/components/FirstPattern.vue";


export default {
  name: "categories-add",
  title: "افزودن دسته بندی بلاگ",
  data() {
    return {
      defaultCreateData: {
        isDeleted: false,
        blogCategoryId: 0,
        name: '',
        type: 1,
        schema: null,
        seoTitle: "",
        seoDescription: "",
        seourl: ""
      },
      typeOptions: [
        {label: 'بلاگ', value: 1},
        {label: 'مجله', value: 2},
      ],
      schemaOptions: [
        {label: '1', value: 1},
      ],
      category: null,
      categoryProperties: null,
      addNewCategoryProperty: false,
      updateCategoryPropertyData: null,
    }
  },
  methods: {
    async createBlogCategory() {
      let _this = this;

      let blogCategoryCreateRequest = new BlogCategoryCreateRequest(_this);
      blogCategoryCreateRequest.setParams(_this.defaultCreateData);
      await blogCategoryCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی جدید اضافه شد.`,
          },
        })
        _this.$router.push("/pages/blog/categories/list");
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CheckIcon',
            variant: 'danger',
            text: `دسته بندی با این نام موجود است.`,
          },
        })
        console.log(error)
      })
    },
    copyText() {
      this.$refs.myInput.focus();
      document.execCommand('copy');
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `آدرس بلاگ کپی شد.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.defaultCreateData.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.defaultCreateData.seourl = newValue
          // Remove all characters that are NOT number
          this.defaultCreateData.seourl = this.defaultCreateData.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.defaultCreateData.seourl = this.defaultCreateData.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.defaultCreateData.seourl === "") {
          this.defaultCreateData.seourl = null;
        }
      },
    },
  },
  components: {
    FirstPattern,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    vSelect,
  }
}
</script>

<style>
.serp-arrow {
  border-bottom-color: rgb(0, 102, 33);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 4px;
  border-right-color: rgba(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 4px;
  border-top-color: rgb(0, 102, 33);
  border-top-style: solid;
  border-top-width: 5px;
  color: rgb(128, 128, 128);
  cursor: default;
  font-family: arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  height: 0px;
  position: absolute;
  line-height: 27px;
  margin-left: 3px;
  margin-top: 6px;
  text-align: center;
  user-select: none;
  visibility: visible;
  white-space: nowrap;
  width: 0px;
}

.serp-title {
  color: rgb(26, 13, 171)!important;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 21.6px;
  text-align: left;
  text-decoration: none;
  visibility: visible;
  white-space: nowrap;
}

.serp-url {
  color: rgb(0, 102, 33)!important;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  visibility: visible;
  white-space: nowrap;
}

.serp-description {
  color: rgb(84, 84, 84)!important;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18.2px;
  text-align: left;
  visibility: visible;
  word-wrap: break-word;
}
</style>
